import { useContext } from 'react'
import type { ReactElement } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { ToastContext } from 'contexts/ToastContext'
import { AuthContext } from 'contexts/AuthContext'
import Layout from 'layouts/auth'
import AuthFormHeader from 'components/auth/auth-form-header'
import LoginForm from 'components/auth/login-form'

export default function LoginScreen() {
  const router = useRouter()
  const { addToast } = useContext(ToastContext)
  const { signIn } = useContext(AuthContext)

  async function handleSignIn(data: any) {
    const logged = await signIn(data)
    if(logged) {
      addToast('Acesso realizado', 'Seja bem vindo a Pureskills','info')
      const redirect = router.query.url || '/dashboard'
      router.push(redirect as string)
    } else {
      addToast('Não foi possível entrar', 'Verifique seu e-mail e senha e tente novamente.','negative')
    }
  }

  return (
    <>
      <Head>
        <title>Pureskills - Login</title>
        <meta name="description" content="Pureskills - Login" />
      </Head>

      <AuthFormHeader
        title=""
        text=""
        icon="LogIn"
      />
      <div className="form-block">
        <LoginForm onSubmit={handleSignIn} />
      </div>
    </>
  )
}

LoginScreen.getLayout = function getLayout(page: ReactElement) {
  return (
    <Layout>
      {page}
    </Layout>
  )
}
