export function removeBlanks(classes: string) {
  return classes.replace(/[\s\n\r\t]+/g, ' ').trim()
}

export function formatUUID(uuid: string) {
  if(!uuid) return ''
  if(!uuid.split('-')) return uuid

  return uuid.split('-')[0]
}
