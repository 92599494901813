import React, { useId } from 'react'
import { useField, ErrorMessage } from 'formik'

import { removeBlanks } from 'lib/text'
import Icon, { IconName } from 'components/common/icon'
import TextLabel from 'components/common/text-label'
import HelperText from 'components/common/helper-text'

import style from './text-field.module.scss'

interface Props {
  name: string
  label?: string
  value?: string
  type?: string
  iconType?: "copy" | "visibility" | "externalLink"
  iconClick?: Function
  placeholder?: string
  autoComplete?: string
  readOnly?: boolean
  handleBlur?: Function
  dataAttr?: object
  prepend?: string
  helperText?: string
  optional?: boolean
  required?: boolean
  disabled?: boolean
  size?: 'small' | 'large'
  maxLength?: number
}

const TextField = (props: Props) => {
  const fieldId = `${props.name}-${useId()}`
  const [field, meta] = useField({ name: props.name })
  const iconTypes = {
    copy: "Files",
    visibility: "Eye",
    externalLink: "ExternalLink"
  }

  const maxLength = props.maxLength

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(e)
  }

  return (
    <div className={style.wrapper}>
      {props.label &&
        <TextLabel
          label={props.label}
          htmlFor={fieldId}
          optional={props.optional}
          required={props.required}
        />
      }
      <div className={props.prepend ? style.fieldGrid : ''}>
        {props.prepend &&
          <div className={style.prependInput}>
            <span>{props.prepend}</span>
          </div>
        }
        <div className={style['container-input']}>
          <input
            {...field}
            value={field.value || ""}
            id={fieldId}
            type={props.type || "text"}
            className={removeBlanks(`
              ${style.textField}
              ${props.size === 'small' ? style.textFieldSmall : style.textFieldLarge}
              ${props.iconType ? style.withIcon : ''}
              ${meta.error && meta.touched ? style.negative : ''}
            `)}
            disabled={props.disabled}
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
            readOnly={props.readOnly}
            onChange={(e: any) => handleChange(e)}
            onBlur={(e) => {
              field.onBlur(e)
              props.handleBlur && props.handleBlur(e, meta)
            }}
            {...props.dataAttr}
          />
        </div>
        {props.iconType &&
          <div
            className={removeBlanks(`
              ${style.icon}
              ${props.iconClick ? style.clickable : ''}
            `)}
            onClick={() => { props.iconClick && props.iconClick() }}
            data-testid="inner-icon-field"
          >
            <Icon name={iconTypes[props.iconType] as IconName} className={`${iconTypes[props.iconType] === 'ExternalLink' && style.externalLink}`} />
          </div>
        }
      </div>

      <div className={style.containerCharacters}>
        <ErrorMessage name={props.name}>{msg =>
          <div role="alert"><HelperText text={msg} type="negative" /></div>
        }</ErrorMessage>
        {
          props.maxLength &&
          <div className={` ${meta.error && meta.touched ? style.charactersNegative : style.characters}`}>
            {field.value?.length || 0}/{maxLength}
          </div>
        }
      </div>

      {!meta.error && props.helperText && <>
        <HelperText text={props.helperText} type="neutral" />
      </>}
    </div>
  )
}

export default TextField
