import style from './background-video.module.scss'

const DefaultBGVideo = () => {
  return (
    <>
      <div
        data-testid="background-video"
        className={`
          ${style['background-video']}
          ${style['w-background-video']}
        `}
      >
        <video
          role="video"
          autoPlay
          loop
          style={{
            backgroundImage: `url('/background_video-bg-pureskills-poster-00001.jpg')`
          }}
          muted
          playsInline
        >
          <source
            src="/video-bg-pureskills.mp4"
          />
          <source
            src="/video-bg-pureskills.webm"
          />
        </video>
      </div>
    </>
  )
}

export default DefaultBGVideo
