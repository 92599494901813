import { ReactNode } from 'react'

import UnloggedHeader from 'components/layout/unlogged-header'
import SectionFooter from 'components/layout/section-footer'
import DefaultBGVideo from 'components/auth/background-video'

import style from './auth.module.scss'

export default function Layout({children}: {children: ReactNode}) {
  return (
    <>
      <div className={style['auth-page']}>
        <div className={style['content-area']}>
          <UnloggedHeader />
          <div className={style['main-content']}>
            {children}
          </div>
          <SectionFooter />
        </div>
        <div className={style['video-area']}>
          <DefaultBGVideo />
        </div>
      </div>
    </>
  )
}
