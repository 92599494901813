import Icon, { IconName } from 'components/common/icon'
import style from './helper-text.module.scss'

interface Props {
  type?: 'neutral' | 'negative' | 'positive';
  text: string
}

export const HelperText = (props:Props) => {
  const helperType = props.type || 'neutral'
  const iconName = {
    neutral: "Info",
    negative: "AlertTriangle",
    positive: "CheckCircle",
  }

  return (
    <div className={style.container}>
      <Icon name={iconName[helperType] as IconName} className={`${style.icon} ${style[helperType]}`} size={16} />
      <p className={`${style.text} ${style[helperType]}`}
      >{props.text}</p>
    </div>
  )
}

export default HelperText
