import React from 'react'
import { HeaderProps } from 'types/props'
import Icon from 'components/common/icon'
import style from './form-header.module.scss'

const FormHeader = (props: HeaderProps) => {
  return (
    <div data-testid="auth-form-header">
      <div className={style['form-header']}>
        {props.icon &&
          <Icon className={style['icon']} name={props.icon} />
        }
      </div>
      <h3 className={style['title']} dangerouslySetInnerHTML={{__html: props.title}} />
      {props.text && <p role="text" className=' white-off-text'>{props.text}</p> }
    </div>
  )
}

export default FormHeader
