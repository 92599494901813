import { useRouter } from 'next/router'
import { forwardRef, MouseEventHandler, ReactElement, ReactNode, RefObject } from 'react'
import { removeBlanks } from 'lib/text'
import Icon, { IconName } from 'components/common/icon'
import style from './form-button.module.scss'

interface ButtonProps {
  type?: "button" | "submit" | "reset",
  name?: "primary" | "secondary" | "destructive" | "negative" | "upload",
  size?: "small" | "large",
  icon?: IconName,
  onClick?: Function,
  to?: string,
  disabled?: boolean,
  children?: ReactNode,
  dataAttr?: object,
  iconSize?: number,
  className?: string
}

const FormButton = forwardRef<ReactElement, ButtonProps>((props: ButtonProps, ref) => {
  const router = useRouter()
  const hasIconOnly = !props.children && props.icon;
  const buttonClasses = `${style[props?.name || 'primary']} ${style[props?.size || 'large']} ${hasIconOnly ? style.iconOnly : ''}`

  const handleClick:MouseEventHandler<HTMLButtonElement> = (event) => {
    if (props.onClick) props.onClick(event)
    if (props.to != null && props.type === 'button') router.push(String(props.to))
  }

  return (
    <button
      ref={ref as RefObject<HTMLButtonElement>}
      data-testid='form-button'
      disabled={props.disabled}
      className={removeBlanks(`
        ${style.button}
        ${buttonClasses}
        ${props.className}
      `)}
      type={props.type || 'submit'}
      role={props.type || 'submit'}
      onClick={handleClick}
      {...props.dataAttr}
    >
      {props.icon && (
        <span className={style.icon}>
          <Icon name={props.icon} size={props.iconSize} />
        </span>
      )}
      {props.children && <span className={style.text}>{props.children}</span>}
    </button>
  )
})
FormButton.displayName = 'FormButton'

export default FormButton
