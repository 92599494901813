import { removeBlanks } from 'lib/text'
import style from './text-label.module.scss'

interface Props {
  label: string,
  disabled?: boolean,
  error?: boolean,
  htmlFor?: string,
  optional?: boolean
  required?: boolean
}

const TextLabel = (props: Props) => {
  return (
    <div className={style['label']} aria-disabled="true">
      <label
        className={removeBlanks(`
          ${style['text']}
          ${props.disabled ? style['disabled'] : ''}
          ${props.error ? style['negative'] : ''}
        `)}
        htmlFor={props.htmlFor}
        data-testid="text-label"
      >
        {props.label}
      </label>
      {props.optional &&
        <div data-testid="optional-tag" className={style.optional}>opcional</div>
      }
      {props.required &&
        <div data-testid="required-tag" className={style.required}>obrigatório</div>
      }
    </div>
  )
}

export default TextLabel
