import Link from 'next/link'
import style from './section-footer.module.scss'

const SectionFooter = () => {
  return (
    <>
      <div className={style['footer']}>
        <p>© Pureskills Brasil Tecnologia</p>
        <p>
          <a href="https://ajuda.pureskills.com/" target="_blank" rel="noreferrer">Central de Ajuda</a>
          <span> | </span>
          <Link href="/auth/forgot-password" passHref><a className={style['color-link']}>Recuperar senha</a></Link>
          <span> | </span>
          <a href="https://site.pureskills.com/termos-de-uso" target="_blank" rel="noreferrer">Termos de Uso</a>
          <span> | </span>
          <a href="https://site.pureskills.com/politica-de-privacidade" target="_blank" rel="noreferrer">Política de Privacidade</a>.
        </p>

      </div>
    </>
  )
}

export default SectionFooter
